import { ProductStatus } from "@madhive/mad-sdk";
import { omit } from "lodash";
import {
  ABORT_PRODUCT_REQUEST,
  CREATE_PRODUCT_PENDING,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_PENDING,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_PENDING,
  GET_ALL_PRODUCTS_SUCCESS,
  ProductsActionTypes,
  ProductsState,
  SAVE_PRODUCT_FAILURE,
  SAVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_PENDING
} from "./types";

export const productsInitialState: ProductsState = {
  byId: {},
  archivedById: {},
  isLoading: false,
  receivedAllAt: null,
  isUpdating: false,
  getError: null,
  updateError: null
};

export const productsReducer = (
  state = productsInitialState,
  action: ProductsActionTypes
): ProductsState => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_PENDING:
      return {
        ...state,
        isLoading: true,
        getError: null
      };
    case GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        getError: action.meta.error.message
      };
    case GET_ALL_PRODUCTS_SUCCESS: {
      return {
        ...state,
        ...action.payload.reduce(
          (accumulator, product) => {
            const { status, id } = product;
            const { byId, archivedById } = accumulator;

            if (
              status === ProductStatus.READY ||
              status === ProductStatus.DRAFT
            ) {
              byId[id] = product;
            }

            if (status === ProductStatus.ARCHIVED) {
              archivedById[id] = product;
            }

            return accumulator;
          },
          {
            byId: {},
            archivedById: {}
          }
        ),
        isLoading: false,
        receivedAllAt: action.meta.timestamp,
        getError: null,
        updateError: null
      };
    }
    case ABORT_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        getError: null,
        updateError: null
      };
    case CREATE_PRODUCT_PENDING:
      return {
        ...state,
        isUpdating: true,
        updateError: null
      };
    case UPDATE_PRODUCT_PENDING:
      return {
        ...state,
        isUpdating: true,
        updateError: null
      };
    case SAVE_PRODUCT_SUCCESS: {
      const {
        product,
        product: { status, id }
      } = action.payload;

      const baseFields = {
        isUpdating: false,
        updateError: null
      };

      if (status === ProductStatus.READY || status === ProductStatus.DRAFT) {
        return {
          ...state,
          ...baseFields,
          byId: {
            ...state.byId,
            [id]: product
          },
          archivedById: omit(state.archivedById, id)
        };
      }

      if (status === ProductStatus.ARCHIVED) {
        return {
          ...state,
          ...baseFields,
          byId: omit(state.byId, id),
          archivedById: {
            ...state.archivedById,
            [id]: product
          }
        };
      }

      return {
        ...state,
        ...baseFields
      };
    }
    case SAVE_PRODUCT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateError: action.meta.error.message
      };
    case DELETE_PRODUCT_PENDING:
      return {
        ...state,
        isUpdating: true,
        updateError: null
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        byId: omit(state.byId, action.payload.productId),
        archivedById: omit(state.archivedById, action.payload.productId),
        isUpdating: false,
        updateError: null
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateError: action.meta.error.message
      };
    default:
      return state;
  }
};
