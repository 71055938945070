/* eslint-disable camelcase */
import { ClientDeviceNames, ServiceStatus } from "../types";
import { serviceToMadhiveSupplyCosts } from "./supplyCosts";
import { madhiveSupplyCostsToService } from "./supplyCosts";
import { inventoryPackagesToService, serviceToInventoryPackages } from "./inventoryPackage";
export var ProductStatus;
(function (ProductStatus) {
    ProductStatus["DRAFT"] = "Draft";
    ProductStatus["READY"] = "Ready";
    ProductStatus["ARCHIVED"] = "Archived";
})(ProductStatus || (ProductStatus = {}));
export const productStatusToServiceStatus = (status) => {
    switch (status) {
        case ProductStatus.READY:
            return ServiceStatus.READY;
        case ProductStatus.DRAFT:
            return ServiceStatus.DRAFT;
        case ProductStatus.ARCHIVED:
            return ServiceStatus.ARCHIVED;
        default:
            return ServiceStatus.DRAFT;
    }
};
export const serviceStatusToProductStatus = (status) => {
    switch (status) {
        case ServiceStatus.READY:
            return ProductStatus.READY;
        case ServiceStatus.DRAFT:
            return ProductStatus.DRAFT;
        case ServiceStatus.ARCHIVED:
            return ProductStatus.ARCHIVED;
        default:
            return ProductStatus.DRAFT;
    }
};
export const productToServiceProduct = (product) => {
    const inventory_packages = inventoryPackagesToService(product.inventoryPackages);
    const status = productStatusToServiceStatus(product.status);
    // don't include updated or updatedBy, as they'd be ignored on the backend anyway
    return {
        id: product.id,
        name: product.name.trim(),
        parent: product.parent,
        status,
        dayparts: product.dayparts,
        whitelist_id: product.whitelistId,
        device_caps: product.deviceCaps,
        // frontend does conversions from satoshis to dollars and back, and that sometimes added a nasty little decimal at the end
        // Ex: dollarsToSatoshis(32.011) --> 3201100000.0000005
        // ecpm caps at 100 in dollars, so truncating here, on a figure represented in satoshis, is fine
        ecpm: Math.trunc(product.ecpm),
        vcr_target: product.vcrTarget,
        frequencies: product.frequencies,
        inventory_set: product.inventorySet,
        tentpole_inv_group_id: product.tentpoleInvGroupId,
        inventory_packages,
        ...madhiveSupplyCostsToService(product)
    };
};
export const serviceProductToProduct = (product) => {
    const inventoryPackages = serviceToInventoryPackages(product.inventory_packages);
    const status = serviceStatusToProductStatus(product.status);
    return {
        id: product.id,
        name: product.name,
        parent: product.parent,
        updated: product.updated ? new Date(product.updated) : undefined,
        updatedBy: product.updated_by,
        status,
        dayparts: product.dayparts,
        whitelistId: product.whitelist_id,
        deviceCaps: {
            [ClientDeviceNames.CTV]: 0,
            [ClientDeviceNames.MOBILE]: 0,
            [ClientDeviceNames.DESKTOP]: 0,
            [ClientDeviceNames.TABLET]: 0,
            ...(product.device_caps || {})
        },
        ecpm: product.ecpm,
        vcrTarget: product.vcr_target,
        frequencies: product.frequencies || [],
        inventorySet: product.inventory_set,
        tentpoleInvGroupId: product.tentpole_inv_group_id,
        inventoryPackages,
        ...serviceToMadhiveSupplyCosts(product)
    };
};
