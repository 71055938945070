import { FilterTypes, toQueryParam } from "../../handlers/filter";
import { ProductStatus, productStatusToServiceStatus } from "../../../types";
/**
 * @param filters: array of SDK-style product filters.
 * @return: a proper query string for the supplied filters.
 */
const wheresToQueryParams = (filters) => {
    let params = "";
    if (filters?.where) {
        for (const filter of filters.where) {
            switch (filter.field) {
                case "status": {
                    const value = toQueryParam(filter);
                    params = `${params}&statuses=${value}`;
                    break;
                }
            }
        }
    }
    return params;
};
/**
 * @param filters: array of SDK-style advertisers filters.
 * @param sort: optional. Information on the field and direction of sorting, if any.
 * @return: a proper query string for the supplied filters and sort.
 */
export const toQueryParams = (filters, sort) => {
    const where = [...(filters?.where ?? [])];
    const filterStatus = where.find(({ field }) => field === "status");
    if (!filterStatus) {
        where.push({
            field: "status",
            type: FilterTypes.IN,
            /**
             * We default to these three statuses to restrict users from querying other statuses,
             * even though the database contains additional ones we do not wish to include.
             * @default:Ensures that only products with status READY, DRAFT, or ARCHIVED are returned.
             */
            value: [ProductStatus.READY, ProductStatus.DRAFT, ProductStatus.ARCHIVED]
        });
    }
    const serviceWhere = where.map((filter) => {
        if (filter.field === "status") {
            return {
                ...filter,
                value: Array.isArray(filter.value)
                    ? filter.value.map((status) => productStatusToServiceStatus(status))
                    : productStatusToServiceStatus(filter.value)
            };
        }
        return filter;
    });
    let params = wheresToQueryParams({
        ...filters,
        where: serviceWhere
    });
    if (sort && sort.field && sort.direction) {
        params = `${params}&sortBy=${sort.field}%20${sort.direction}`;
    }
    if (params.length) {
        return `?${params}`;
    }
    return "";
};
