import { NOOP } from "../../utils";
import { useCollection } from "../../utils/collections";
import { useBehaviorSubject } from "../rxjs";
/**
 * Utils for list data
 * @param collection collection name
 * @param filters filters on the collection
 * @returns Observer and promise/observable handler to the collection
 */
export const useListMadHooksUtils = (collection, options) => {
    const handler = useCollection(collection);
    const observer = useBehaviorSubject(collection, options, {
        // using an empty array here so we don't have to check for this all over the place
        data: [],
        isLoading: false,
        isLoaded: false,
        isDeleted: false,
        isInitializing: true,
        refresh: NOOP
    });
    return { observer, handler };
};
